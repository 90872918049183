<template>
  <div class="base-date-picker text-left">
    <div class="relative">
      <div class="w-full relative">
        <input
          :value="modelValue"
          type="date"
          class="w-full focus:outline-none outline-none opacity-0 z-50 py-3"
          :max="maxdate"
          :min="mindate"
          @input="handleChange"
          @focus="handleFocus"
          :class="{
            'py-6': isIos() && !modelValue,
          }"
        />
        <p
          v-if="modelValue"
          class="absolute top-1/2 transform -translate-y-1/2 left-4"
        >
          {{ modelValue }}
        </p>
        <p
          v-else
          class="
            absolute
            top-1/2
            transform
            -translate-y-1/2
            left-4
            text-gray-400
          "
        >
          {{ placeholder }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import * as Utils from '@/utils/index';
export default {
  emits: ['focus', 'update:modelValue'],
  name: 'BaseDatePicker',
  props: {
    modelValue: {
      type: String,
    },
    mindate: {
      type: String,
    },
    maxdate: {
      type: String,
    },
    placeholder: {
      type: String,
    },
  },
  methods: {
    // showDatepicker() {
    //   document.getElementById('dateinput').focus();
    // },
    handleChange(event) {
      this.$emit(
        'update:modelValue',
        Utils.formatBirthday(new Date(event.target.value)),
      );
    },
    handleFocus() {
      this.$emit('focus');
    },
    isIos() {
      return (
        [
          'iPad Simulator',
          'iPhone Simulator',
          'iPod Simulator',
          'iPad',
          'iPhone',
          'iPod',
        ].includes(navigator.platform) ||
        // iPad on iOS 13 detection
        (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.base-date-picker {
  background-color: #fff;
  border-radius: 40px;
  input {
    -webkit-appearance: none;
    -moz-appearance: none;
    position: relative;
  }
  input[type='date']::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    color: transparent;
    background: transparent;
  }
  input[type='date']::-webkit-inner-spin-button {
    z-index: 1;
  }

  /* adjust clear button */
  input[type='date']::-webkit-clear-button {
    z-index: 1;
  }
}
</style>
