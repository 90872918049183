import Axios from '../services/axios';

const PATH = 'lender/customers';
export async function uploadFrontImage(formdata: any) {
  return await Axios.post(`${PATH}/nic-front`, formdata);
}

export async function uploadBackImage(formdata: any) {
  return await Axios.post(`${PATH}/nic-back`, formdata);
}

export async function uploadFace(formdata: any) {
  return await Axios.post(`${PATH}/face-matching`, formdata);
}

export function confirmData(data: any) {
  return Axios.post(`${PATH}/ocr-confirm`, data);
}
