<template>
  <div
    class="bg-white base-select p-2 pl-4 relative"
    style="border-radius: 40px"
  >
    <select
      class="focus:outline-none w-full py-1 bg-transparent"
      :value="modelValue"
      @change="handleChoose"
      :class="{
        'font-bold': bold,
        'text-gray-400': modelValue == '',
      }"
    >
      <option v-for="item in data" :key="item.index" :value="item.val">
        {{ item.name }}
      </option>
    </select>
    <!-- <div
      v-if="!modelValue && !isIos()"
      class="absolute top-1/2 transform -translate-y-1/2 left-4 text-gray-400"
    >
      <p>{{ placeholder }}</p>
    </div> -->
  </div>
</template>
<script>
export default {
  name: ' BaseSelect',
  props: {
    modelValue: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    data: {
      type: Array,
      defalut: [],
    },
    bold: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleChoose(event) {
      this.$emit('update:modelValue', event.target.value);
    },
    isIos() {
      return (
        [
          'iPad Simulator',
          'iPhone Simulator',
          'iPod Simulator',
          'iPad',
          'iPhone',
          'iPod',
        ].includes(navigator.platform) ||
        // iPad on iOS 13 detection
        (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.base-select {
  select {
    height: 32px;
  }
}
</style>
