
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    step: {
      type: Number,
    },
  },
});
