
import { defineComponent } from 'vue';
import BaseTextError from '@/components/Base/BaseTextError.vue';
export default defineComponent({
  components: {
    BaseTextError,
  },
  props: {
    onFileChange: {
      type: Function,
    },
    nicImageBack: {
      type: String,
    },
    errorMessages: {
      type: String,
    },
  },
});
