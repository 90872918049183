
import { defineComponent, ref } from 'vue';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import EkycStep from './EkycStep.vue';
import NicFront from './Nicfront.vue';
import NicBack from './Nicback.vue';
import FacePhoto from './FacePhoto.vue';
import BaseInput from '@/components/Base/BaseInput.vue';
import BaseDatePicker from '@/components/Base/BaseDatePicker.vue';
import EkycWarning from './EkycWarning.vue';
import BaseButton from '@/components/Base/BaseButton.vue';
import BaseSelect from '@/components/Base/baseSelect.vue';
import BaseModal from '@/components/Base/BaseModal.vue';
import BaseError from '@/components/Base/BaseTextError.vue';
import {
  uploadFrontImage,
  uploadBackImage,
  uploadFace,
  confirmData,
} from '@/apis/ApiEkyc';
import { mapMutations, mapGetters, mapActions } from 'vuex';
import constRouter from '@/constants/constRouter';
import * as Analytics from '@/services/analytics';
export default defineComponent({
  components: {
    DefaultLayout,
    NicFront,
    NicBack,
    FacePhoto,
    EkycStep,
    EkycWarning,
    BaseButton,
    BaseInput,
    BaseDatePicker,
    BaseSelect,
    BaseModal,
    BaseError,
  },
  setup() {
    const step = ref(0);
    return {
      step,
    };
  },
  data() {
    return {
      loading: false,
      openModal: false,
      nicImageFront: '',
      nicImageFrontFile: '',
      nicImageBack: '',
      nicImageBackFile: '',
      faceImage: '',
      faceImageFile: '',
      errorMessages: '',
      nicNumber: '',
      birthday: '',
      fullName: '',
      address: '',
      cardType: '',
      gender: '',
      nicDateProvide: '',
      genderType: [
        {
          name: 'Giới tính',
          val: '',
        },
        {
          name: 'Nam',
          val: '1',
        },
        {
          name: 'Nữ',
          val: '0',
        },
      ],
      dataCardType: [
        {
          name: 'Loại giấy tờ',
          val: '',
        },
        {
          name: 'CMND',
          val: 'cmnd',
        },
        {
          name: 'CCCD',
          val: 'cccd',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getEkyc: 'getEkyc',
    }),
  },
  watch: {
    step(value) {
      if (value === 2) {
        this.nicNumber = this.getEkyc.nicNumber;
        this.birthday = this.getEkyc.birthday;
        this.fullName = this.getEkyc.fullName;
        this.address = this.getEkyc.address;
        this.gender = this.getEkyc.gender === 'N/A' ? '' : this.getEkyc.gender;
        this.cardType = this.getEkyc.cardType;
        this.nicDateProvide = this.getEkyc.nicDateProvide;
      }
    },
  },
  methods: {
    ...mapMutations({
      setEkyc: 'setEkyc',
    }),
    ...mapActions({
      fetchUserInfo: 'fetchUserInfo',
    }),
    handleContinue() {
      this.openModal = false;
      this.fetchUserInfo();
      const packageId = localStorage.getItem('packageId');
      if (packageId) {
        this.$router.push({
          name: constRouter.INVESTMENT_PACKAGE_DETAIL.name,
          params: { id: packageId },
        });
      } else {
        this.$router.push({
          name: constRouter.INVESTMENT_PACKAGE.name,
        });
      }
    },
    closeModal() {
      this.openModal = false;
    },
    onFileChange(e: any) {
      const name = e.target.name;
      const file = e.target.files[0];
      const img = new Image();
      img.src = URL.createObjectURL(file);
      if (name === 'nicImageFront') {
        //img front
        this.nicImageFrontFile = file;
        this.nicImageFront = URL.createObjectURL(file);
      }
      //upload ảnh CMND/CCCD mặt sau
      if (name === 'nicImageback') {
        this.nicImageBackFile = file;
        this.nicImageBack = URL.createObjectURL(file);
        console.log(this.nicImageBack);
      }
      //upload ảnh selfie
      if (name === 'faceImage') {
        this.faceImageFile = file;
        this.faceImage = URL.createObjectURL(file);
      }
    },
    handleUpLoadNicFront() {
      this.loading = true;
      var form = new FormData();
      form.append('nicFront', this.nicImageFrontFile);
      uploadFrontImage(form)
        .then((res) => {
          this.setEkyc(res.data?.info);
          this.step = this.step + 1;
          this.errorMessages = '';
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
          this.errorMessages = err?.data.message;
        });
      Analytics.eventK2A('EKYC', 'UPLOAD_NIC_FRONT', 'Upload mặt trước', '');
    },
    handleUpLoadNicBack() {
      this.loading = true;
      var form = new FormData();
      form.append('nicBack', this.nicImageBackFile);
      uploadBackImage(form)
        .then(() => {
          this.loading = false;
          this.step = this.step + 1;
          this.errorMessages = '';
        })
        .catch((err) => {
          this.loading = false;
          this.errorMessages = err?.data.message;
        });
      Analytics.eventK2A('EKYC', 'UPLOAD_NIC_BACK', 'Upload mặt sau', '');
    },
    handleUpLoadFace() {
      this.loading = true;
      var form = new FormData();
      form.append('facePhoto', this.faceImageFile);
      uploadFace(form)
        .then(() => {
          this.loading = false;
          this.openModal = true;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
          this.errorMessages = err?.data.message;
        });
      Analytics.eventK2A('EKYC', 'UPLOAD_FACE', 'Upload ảnh khuôn mặt', '');
    },
    handleConfirm() {
      this.loading = true;
      confirmData({
        fullName: this.fullName,
        nicNumber: this.nicNumber,
        birthday: this.birthday,
        address: this.address,
        gender: this.gender,
        cardType: this.cardType,
        nicDateProvide: this.nicDateProvide,
      })
        .then(() => {
          this.loading = false;
          this.step = this.step + 1;
          this.errorMessages = '';
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
          this.errorMessages = err?.data.message;
        });
      Analytics.eventK2A('EKYC', 'CONFIRM_INFO', 'Xác nhận thông tin EKYC', '');
    },
    validate() {
      let result = true;
      if (!this.fullName) {
        this.errorMessages = 'Bạn chưa nhập Họ tên';
        result = false;
        return result;
      }
      if (!this.birthday) {
        this.errorMessages = 'Bạn chưa chọn Ngày sinh';
        result = false;
        return result;
      }
      if (!this.gender) {
        this.errorMessages = 'Bạn chưa chọn giới tính';
        result = false;
        return result;
      }
      if (!this.cardType) {
        this.errorMessages = 'Bạn chưa chọn Loại giấy tờ';
        result = false;
        return result;
      }
      if (!this.nicNumber) {
        this.errorMessages = 'Bạn chưa nhập số CMND/CCCD';
        result = false;
        return result;
      }
      if (!this.nicDateProvide) {
        this.errorMessages = 'Bạn chưa chọn Ngày cấp';
        result = false;
        return result;
      }
      if (!this.address) {
        this.errorMessages = 'Bạn chưa nhập Địa chỉ';
        result = false;
        return result;
      }
      return result;
    },
    handleClick() {
      if (this.step === 0) {
        if (this.nicImageFront) {
          this.handleUpLoadNicFront();
        } else {
          this.errorMessages = 'Bạn chưa chọn ảnh mặt trước CMND/CCCD';
        }
        return;
      }
      if (this.step === 1) {
        if (this.nicImageBack) {
          this.handleUpLoadNicBack();
        } else {
          this.errorMessages = 'Bạn chưa chọn mặt sau CMND/CCCD';
        }
        return;
      }
      if (this.step === 2) {
        if (this.validate()) {
          this.handleConfirm();
        }
        return;
      }
      if (this.step === 3) {
        if (this.faceImage) {
          this.handleUpLoadFace();
        } else {
          this.errorMessages = 'Bạn chưa chọn ảnh khuôn mặt';
        }
      }
    },
  },
});
